import { ICategoriesApi } from '../types';
import { instance } from './instance';

export const categoriesApi: ICategoriesApi = {
  getAllCategories: async () => await instance.get('/category/all/'),
  getCategory: async (slug = 'all') =>
    (await instance.get(`/category/${slug}/`)).data.data,
  createCategory: async (payload) =>
    await instance.post('/admin/category/create/', payload),
  updateCategory: async (payload, id) =>
    await instance.put(`/admin/category/${id}`, payload),

  rankCategory: async (payload) =>
    await instance.post(`/admin/category/rank`, payload),

  updateCategoryRank: async (payload, slug) =>
    await instance.put(`/admin/category/rank/${slug}`, payload),
};
