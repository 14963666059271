import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { RootState } from '..';
import { couponsApi } from '../../api';
import { ICoupon, ICreateCoupon } from '../../types';

interface ICouponState {
  loading: boolean;
  coupons: ICoupon[];
  coupon: ICoupon | null;
}

const initialState: ICouponState = {
  loading: false,
  coupons: [],
  coupon: null,
};

export const couponSlice = createSlice({
  name: 'categories',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(createCoupon.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createCoupon.fulfilled, (state) => {
      state.loading = false;
      toast.success('Coupon created successfully.');
    });
    builder.addCase(createCoupon.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(getAllCoupons.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAllCoupons.fulfilled, (state, { payload }) => {
      state.coupons = payload;
      state.loading = false;
    });
    builder.addCase(getAllCoupons.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(getCoupon.pending, (state) => {
      state.loading = true;
      state.coupon = null;
    });
    builder.addCase(getCoupon.fulfilled, (state, { payload }) => {
      state.coupon = payload;
      state.loading = false;
    });
    builder.addCase(getCoupon.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(updateCoupon.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateCoupon.fulfilled, (state) => {
      state.loading = false;
      toast.success('Coupon updated successfully.');
    });
    builder.addCase(updateCoupon.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(bulkUploadCoupon.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(bulkUploadCoupon.fulfilled, (state) => {
      state.loading = false;
      toast.success('Coupon uploaded successfully.');
    });
    builder.addCase(bulkUploadCoupon.rejected, (state) => {
      state.loading = false;
    });
  },
});

const createCoupon = createAsyncThunk(
  'createCoupon',
  async (params: { payload: ICreateCoupon; cb?: () => void }, { dispatch }) => {
    try {
      const { payload, cb } = params;
      const { data } = await couponsApi.createCoupon(payload);
      cb && cb();
      dispatch(getAllCoupons());
      return data;
    } catch (error: any) {
      throw error;
    }
  }
);

const getAllCoupons = createAsyncThunk('getAllCoupons', async () => {
  try {
    const { data } = await couponsApi.getAllCoupons();
    return data.data;
  } catch (error: any) {
    throw error;
  }
});

const updateCoupon = createAsyncThunk(
  'updateCoupon',
  async (
    params: {
      payload: ICreateCoupon;
      cb?: () => void;
      id: string;
    },
    { dispatch }
  ) => {
    try {
      const { payload, cb, id } = params;
      const { data } = await couponsApi.updateCoupon(payload, id);
      cb && cb();
      dispatch(getAllCoupons());

      return data.data;
    } catch (error: any) {
      throw error;
    }
  }
);

const getCoupon = createAsyncThunk('getCoupon', async (param: string) => {
  try {
    const { data } = await couponsApi.getCoupon(param);
    return data.data;
  } catch (error: any) {
    throw error;
  }
});

const bulkUploadCoupon = createAsyncThunk(
  'bulkUploadCoupon',
  async (
    params: {
      payload: FormData;
      cb?: () => void;
      code: string;
    },
    { dispatch }
  ) => {
    try {
      const { payload, cb, code } = params;
      const { data } = await couponsApi.bulkUploadCoupon(payload, code);
      cb && cb();
      dispatch(getAllCoupons());

      return data.data;
    } catch (error: any) {
      throw error;
    }
  }
);

export const couponSelector = (state: RootState) => state.coupons;
export {
  createCoupon,
  getAllCoupons,
  getCoupon,
  updateCoupon,
  bulkUploadCoupon,
};

export default couponSlice.reducer;
