import { FC, SyntheticEvent, useEffect, useState } from 'react';
import { close as closeIcon } from '../../../assets/svg';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import {
  categorySelector,
  getAllCategories,
  getBrandCategoryRanks,
  rankCategory,
  updateCategoryRank,
} from '../../../store/slices';
import { ICategory, RankCategory } from '../../../types';
import {
  IOnChange,
  PrimaryButton,
  PrimaryInput,
  SecondaryButton,
  SelectInput,
} from '../shared';

interface ICreateGroupModal {
  edit?: boolean;
  toggle: () => void;
  brandId: number;
  categorySlug?: string;
  position?: number;
  categoryId?: number;
}

export const CreateGroup: FC<ICreateGroupModal> = ({
  edit,
  toggle,
  brandId,
  position,
  categoryId,
  categorySlug,
}) => {
  const dispatch = useAppDispatch();
  const { categories } = useAppSelector(categorySelector);

  const [payload, setPayload] = useState<RankCategory>({
    brand: brandId,
    position: position || 0,
    category: 0,
  });

  const onChangeCategory: IOnChange<ICategory> = (option) => {
    if (option)
      setPayload({
        ...payload,
        category: (option as ICategory).id,
      });
  };

  const _createGroup = (e?: SyntheticEvent<HTMLFormElement>) => {
    e?.preventDefault();

    if (edit && categorySlug) {
      return dispatch(
        updateCategoryRank({
          payload,
          slug: categorySlug,
          cb: () => {
            dispatch(getBrandCategoryRanks(brandId));
            toggle();
          },
        })
      );
    }

    dispatch(
      rankCategory({
        payload,
        cb: () => {
          dispatch(getBrandCategoryRanks(brandId));
          toggle();
        },
      })
    );
  };

  useEffect(() => {
    if (edit && categoryId && brandId) {
      setPayload({
        position: position || 0,
        category: categoryId,
        brand: brandId,
      });
    }
  }, [edit, brandId, categoryId, position]);

  useEffect(() => {
    dispatch(getAllCategories());
  }, [dispatch, categorySlug]);

  return (
    <div className="bg-white p-6">
      <div className="flex justify-between items-center">
        <p className="bold text-2xl">{`${
          edit ? 'Edit' : 'Create'
        } category`}</p>
        <button onClick={toggle}>
          <img src={closeIcon} alt="close" />
        </button>
      </div>

      <form className="mt-5" onSubmit={_createGroup}>
        <div className="flex flex-wrap justify-between items-end">
          {!edit && (
            <SelectInput
              singleSelect
              name="categories"
              label="Choose a category to rank for this brand"
              placeholder="Select category"
              options={categories}
              handleChange={onChangeCategory}
              getOptionValue={(item) => `${item.id}`}
              value={categories.filter((g) => payload?.category === g.id)}
              getOptionLabel={(item) => item.name ?? ''}
              className="z-20 relative"
            />
          )}

          <PrimaryInput
            onChange={(e) =>
              setPayload({
                ...payload,
                position: parseInt(e.currentTarget.value),
              })
            }
            value={payload?.position ?? ''}
            placeholder="Enter category position"
            name="position"
            label="What is the position of this category?"
            className="w-5/12 sm:w-full"
            type="number"
          />
        </div>

        <div className="flex justify-end items-center space-x-4 mt-12">
          <SecondaryButton text="Cancel" onClick={toggle} />
          <PrimaryButton
            disabled={!payload.position}
            text={`${edit ? 'Update' : 'Create'} category`}
            type="submit"
          />
        </div>
      </form>
    </div>
  );
};
