import { KeyboardEvent, SyntheticEvent, useEffect, useState } from 'react';
import { logo } from '../../../assets/svg';
import { useAppDispatch } from '../../../hooks';
import { login } from '../../../store/slices';
import { ILoginApi } from '../../../types';
import { PrimaryButton, PrimaryInput } from '../../components/shared';

const Login = () => {
  const dispatch = useAppDispatch();

  const [payload, setPayload] = useState<ILoginApi>({
    username: '',
    password: '',
  });

  const [required, setRequired] = useState<boolean>(false);

  const _handleLogin = (e?: SyntheticEvent<HTMLFormElement>) => {
    e?.preventDefault();
    if (!required) return;
    dispatch(login(payload));
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      _handleLogin();
    }
  };

  const onGetInputValue = (event: SyntheticEvent<HTMLInputElement>) => {
    const value = event.currentTarget.value;
    const name = event.currentTarget.name;

    setPayload({ ...payload, [name]: value });
  };

  useEffect(() => {
    const { username, password } = payload;
    setRequired(username.length > 0 && password.length > 0);
  }, [payload]);

  return (
    <div className="h-screen bg-grey-2 sm:py-12 lg:py-32 flex items-center justify-center">
      <form
        className="flex flex-col items-stretch justify-center bg-white rounded-xl h-full w-full sm:h-auto p-12 sm:w-8/12 lg:w-7/12 xl:w-4/12"
        onSubmit={_handleLogin}
      >
        <img src={logo} alt="logo" className="h-10 w-10 mx-auto" />
        <h1 className="text-neutral-800 bold text-center text-2xl">
          Login to your account
        </h1>

        <PrimaryInput
          onChange={onGetInputValue}
          onKeyDown={handleKeyDown}
          placeholder="Enter username"
          required
          name="username"
          label="Username"
          className="w-full"
        />
        <PrimaryInput
          onChange={onGetInputValue}
          onKeyDown={handleKeyDown}
          placeholder="Enter password"
          required
          name="password"
          label="Password"
          type="password"
          className="w-full"
        />

        <PrimaryButton
          disabled={!required}
          text="Log In"
          type="submit"
          classNames="mt-10 medium"
        />

        <span className="text-center">
          <button
            type="button"
            className="mt-5 text-primary-700 medium focus:outline-none"
          >
            Forgotten your password?
          </button>
        </span>
      </form>
    </div>
  );
};

export default Login;
