import { Cell } from 'react-table';
import { IOrder, IPilot, PilotKyc, PilotVehicle } from '../types';
import { returnRiderStatus } from '../helpers/riders.helpers';

const ridersColumn = [
  {
    Header: 'Pilot Name',
    accessor: 'user',
    Cell: ({ row }: Cell) => {
      if (row.values.user) {
        return `${row.values.user.firstName} ${row.values.user.lastName}`;
      } else {
        return '';
      }
    },
  },
  {
    Header: 'Pilot Status',
    accessor: 'business',
    Cell: ({ row }: Cell) => returnRiderStatus(row.values.business),
  },
  {
    Header: 'Date Created',
    accessor: 'createdAt',
  },
  {
    Header: 'Action',
  },
  {
    Header: 'Quick Actions',
    accessor: 'updatedAt',
  },
];

const pilotDetails = (pilot: IPilot) => [
  {
    title: 'Pilot Name',
    content: `${pilot?.user.firstName} ${pilot?.user.lastName}`,
  },
  { title: 'Phone Number', content: pilot?.user.phoneNumber },
  { title: 'Email', content: pilot?.user.email },
  {
    title: 'Average Delivery Time',
    content: `${pilot?.averageDeliveryTime.toString()} minutes`,
  },
  {
    title: 'Status',
    content: pilot?.status,
  },
  {
    title: 'Employment Type',
    content: pilot?.employmentType,
  },
];

export const kycDetails = (kyc: PilotKyc) => [
  {
    title: 'Status',
    content: `${kyc.status}`,
  },
];

const hiddenBankKeys = ['bankCode', 'id', 'kitchenId', 'partnerId', 'pilotId'];

export const hiddenVehiclesKeys: (keyof PilotVehicle)[] = [
  'createdAt',
  'id',
  'pilotId',
  'updatedAt',
  'isElectric',
];

const formatBankKeys = {
  accountName: 'Account Name',
  accountNumber: 'Account Number',
  bankName: 'Bank Name',
};

export const formatVehicleKeys = {
  id: 'ID',
  createdAt: 'Date Created',
  updatedAt: 'Date Updated',
  pilotId: 'Pilot',
  status: 'Status',
  type: 'Type',
  make: 'Make',
  plateNumber: 'Plate Number',
  isElectric: 'Is Electric',
};

export const pilotInfoCards = (orders: IOrder[]) => [
  {
    title: 'Orders Delivered',
    value: orders.filter((order) => order.status === 'DELIVERED').length,
    bg: 'orange-gradient',
  },
  {
    title: 'Orders Enroute',
    value: orders.filter((order) => order.status === 'ENROUTE').length,
    bg: 'blue-gradient',
  },
  {
    title: 'Total Earnings',
    value: `₦${orders
      .filter((item) => item.status === 'DELIVERED')
      .reduce((sum, a) => sum + a.deliveryCharge, 0)}`,
    bg: 'purple-gradient',
  },
];

export { ridersColumn, hiddenBankKeys, formatBankKeys, pilotDetails };
