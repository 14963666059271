import React, { FC, SyntheticEvent, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { statusColors, orderColumns, orderGroups } from '../../../../constants';
import { useAppDispatch, useAppSelector, useOrder } from '../../../../hooks';
import {
  authSelector,
  getAllOrders,
  getOrder,
  getOrdersStatuses,
  orderSelector,
} from '../../../../store/slices';
import { IOrder, OrderStatus } from '../../../../types';
import {
  ExportCsv,
  NoData,
  Paginator,
  PrimaryButton,
  QuickActions,
  SecondaryButton,
  Table,
  ToggleButton,
} from '../../../components';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import { mapMarker, SvgAlarm } from '../../../../assets/svg';
import Modal from 'react-modal';
import { shouldFlag } from '../../../../helpers';
import { intervalToDuration, differenceInSeconds } from 'date-fns';

const Orders = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { orders, ordersStatuses } = useAppSelector(orderSelector);
  const { user } = useAppSelector(authSelector);
  const { data, pagination } = orders;
  const { admin } = user;

  const [searchParams] = useSearchParams();

  const [activeFilter, setActiveFilter] = useState<
    OrderStatus | '' | 'FLAGGED'
  >('');
  const [displayedOrders, setDisplayedOrders] = useState<IOrder[]>([]);
  const [activeView] = useState<string>('List');
  const [modal, setModal] = useState(0);
  const [orderSlug, setOrderSlug] = useState('');

  const currentPage = React.useMemo(
    () => parseInt(pagination?.currentPage || '1'),
    [pagination?.currentPage]
  );

  const handlePageClick = (data: any) => {
    const page = data.selected + 1;
    dispatch(
      getAllOrders({
        page,
        status:
          activeFilter && activeFilter !== 'FLAGGED'
            ? [activeFilter]
            : undefined,
      })
    );
  };

  const { goToOrder } = useOrder();

  const openModal = (event: SyntheticEvent<HTMLButtonElement>, id?: number) => {
    event.stopPropagation();
    if (id) dispatch(getOrder(id));
    setModal(1);
  };

  const closeModal = () => {
    setModal(0);
  };

  const orderPage = (id: number) => {
    navigate(`/dashboard/order/${id}`);
  };

  const createOrder = () => {
    navigate(`/dashboard/orders/create`);
  };

  const filterOrders = (value: OrderStatus | '') => {
    setActiveFilter(value);
    searchParams.set('status', value);
    navigate(`/dashboard/orders?${searchParams.toString()}`);
  };

  useEffect(() => {
    dispatch(getOrdersStatuses());
    // eslint-disable-next-line
  }, [dispatch]);

  React.useEffect(() => {
    const status = searchParams.get('status');

    status && setActiveFilter(status as OrderStatus);

    dispatch(
      getAllOrders({
        query:
          admin?.kitchens && admin?.kitchens.length
            ? admin?.kitchens[0].id
            : undefined,
        status:
          status && status !== 'FLAGGED' ? [status as OrderStatus] : undefined,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams, dispatch]);

  React.useEffect(() => {
    if (data) {
      const status = searchParams.get('status');
      const filtered = data.filter((o) =>
        status === 'FLAGGED' ? shouldFlag(o) : true
      );
      setDisplayedOrders(filtered);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <>
      <div className="body1">
        <div className="header1">
          <h1 className="semibold text-xl">Order Management</h1>

          <div className="flex items-end space-x-5">
            <SecondaryButton text="Export" onClick={() => setModal(2)} />
            <PrimaryButton text="New Order" onClick={createOrder} />
          </div>
        </div>

        <div className="flex-grow">
          <div className="flex flex-col lg:flex-row items-center space-x-5">
            <div className="flex items-center flex-wrap">
              {orderGroups.map(({ name, value }) => (
                <ToggleButton
                  key={name}
                  active={activeFilter === value}
                  onClick={() => filterOrders(value as OrderStatus)}
                  text={name}
                  count={
                    name === 'Follow Up'
                      ? undefined
                      : name === 'All Orders'
                      ? undefined
                      : ordersStatuses && ordersStatuses[value as OrderStatus]
                  }
                />
              ))}
            </div>
          </div>

          <div className="flex space-x-4 mt-8">
            <input
              placeholder="Search for an order with order slug"
              className="lg:w-3/12 border border-neutral-300 rounded-md px-3 py-2 shadow-xsmall focus:outline-none"
              onChange={(e) => setOrderSlug(e.currentTarget.value)}
            />
            <PrimaryButton
              text="Go to order"
              disabled={orderSlug.length < 10}
              onClick={() => goToOrder(orderSlug)}
            />
          </div>

          {displayedOrders && displayedOrders.length > 0 ? (
            <div className="flex flex-col justify-between flex-grow">
              {activeView === 'List' ? (
                <Table
                  columns={orderColumns()}
                  data={displayedOrders}
                  tableFor={'orders'}
                  openPage={orderPage}
                  quickAction={openModal}
                  paginated
                />
              ) : (
                <OrdersMap data={displayedOrders} />
              )}

              <Paginator
                click={handlePageClick}
                totalPages={pagination?.totalPages || 1}
                selected={currentPage - 1}
                hasNext={pagination?.hasNext || false}
              />
            </div>
          ) : (
            <div className="flex h-full items-center justify-center">
              <NoData
                title="No orders yet"
                description="You haven’t added any kitchen. Start adding kitchens by clicking the button below."
                buttonText="New Order"
                onclick={createOrder}
              />
            </div>
          )}
        </div>
      </div>

      <Modal
        isOpen={modal !== 0}
        onRequestClose={closeModal}
        ariaHideApp={false}
        className={`w-11/12 sm:w-[430px] default-modal`}
        overlayClassName="modal-overlay"
      >
        {modal === 1 && (
          <QuickActions activeFilter={activeFilter} toggle={closeModal} />
        )}

        {modal === 2 && <ExportCsv toggle={closeModal} />}
      </Modal>
    </>
  );
};

const OrdersMap: FC<{ data: IOrder[] }> = ({ data }) => {
  const navigate = useNavigate();

  const { orders } = useAppSelector(orderSelector);
  const { data: orderList } = orders;

  const marker = new L.Icon({
    iconUrl: mapMarker,
    iconRetinaUrl: mapMarker,
    iconSize: [42, 42],
  });

  const orderPage = (id: number) => {
    navigate(`/dashboard/order/${id}`);
  };

  return (
    <div className="relative mt-5 flex-grow">
      <MapContainer
        style={{ height: '100%', width: '100%' }}
        center={[
          orderList[0].deliveryAddress!.latitude,
          orderList[0].deliveryAddress!.longitude,
        ]}
        zoom={13}
        scrollWheelZoom={false}
      >
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        {data.map((order) => (
          <Marker
            key={order.id}
            position={[
              order.deliveryAddress!.latitude,
              order.deliveryAddress!.longitude,
            ]}
            icon={marker}
          >
            <Popup>
              <div
                className="cursor-pointer"
                onClick={() => orderPage(order.id)}
              >
                <p>
                  {order.customer?.fullName ||
                    order.user?.firstName + ' ' + order.user?.lastName ||
                    ' '}
                </p>
                <p>
                  {order.kitchens.map((kitchen) => kitchen.name).join(', ')}
                </p>
                <div className="flex items-center space-x-4 uppercase">
                  <div
                    className={`h-2 w-2 rounded-full mr-2 ${
                      statusColors[order.status as keyof typeof statusColors] ||
                      'bg-pink'
                    }`}
                  />
                  {order.status}
                </div>
              </div>
            </Popup>
          </Marker>
        ))}
      </MapContainer>
    </div>
  );
};

export default Orders;

export const OrderFlag = ({ order }: { order: IOrder }) => {
  const [elapsedTime, setElapsedTime] = React.useState(0);

  const duration = intervalToDuration({ start: 0, end: elapsedTime * 1000 });

  const zeroPad = (num: number) => String(num).padStart(2, '0');

  const formatted = `${zeroPad(duration.hours ?? 0)}:${zeroPad(
    duration.minutes ?? 0
  )}:${zeroPad(duration.seconds ?? 0)}`;

  React.useEffect(() => {
    if (order.timePaid) {
      const initialElapsedTime = differenceInSeconds(
        new Date(),
        new Date(order.timePaid)
      );
      setElapsedTime(initialElapsedTime);

      const intervalId = setInterval(() => {
        setElapsedTime((prevTime) => prevTime + 1);
      }, 1000);

      // Cleanup the interval on unmount
      return () => clearInterval(intervalId);
    }
  }, [order]);

  if (
    order.timePaid &&
    order.status !== 'DELIVERED' &&
    order.status !== 'CANCELED'
  ) {
    return (
      <div
        className={`rounded-full border absolute top-1 left-1 px-1 py-1 text-xs space-x-1 flex items-center uppercase ${
          shouldFlag(order)
            ? 'border-error-200 text-error-200'
            : 'border-success-200 text-success-200 bg-success-50'
        }`}
      >
        <SvgAlarm color={shouldFlag(order) ? '#E26E6A' : undefined} />
        <p className="bold">{`${formatted} ${
          shouldFlag(order) ? '- follow up' : ''
        }`}</p>
      </div>
    );
  }

  return <></>;
};
