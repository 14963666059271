import React, { FC, InputHTMLAttributes, SelectHTMLAttributes } from 'react';
import { NumericFormat, NumericFormatProps } from 'react-number-format';
import Select, {
  ActionMeta,
  GroupBase,
  MultiValue,
  OptionsOrGroups,
  SingleValue,
} from 'react-select';

interface IInput extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
}

export const PrimaryInput: FC<IInput> = ({ label, ...rest }) => {
  return (
    <div className={`flex flex-col mt-7 ${rest.className || 'input'}`}>
      <label
        className="medium text-sm text-neutral-900 flex justify-between items-center"
        htmlFor={rest.name}
      >
        <span>{label}</span>
        {rest.required && <span className="text-primary-700">*</span>}
      </label>

      <input
        id={rest.name}
        {...rest}
        className={`border border-neutral-300 rounded-md px-3 py-4 shadow-xsmall focus:outline-none ${
          rest.disabled ? 'cursor-not-allowed' : ''
        }`}
      />
    </div>
  );
};

interface ITextArea extends InputHTMLAttributes<HTMLTextAreaElement> {
  label?: string;
}

export const TextArea: FC<ITextArea> = ({ label, ...rest }) => {
  return (
    <div className={`flex flex-col mt-7 ${rest.className || 'input'}`}>
      <label
        className="medium text-sm text-neutral-900 flex justify-between items-center"
        htmlFor={rest.name}
      >
        <span>{label}</span>
        {rest.required && <span className="text-primary-700">*</span>}
      </label>

      <textarea
        id={rest.name}
        {...rest}
        className={`border border-neutral-300 rounded-md px-3 py-4 shadow-xsmall focus:outline-none ${
          rest.disabled ? 'cursor-not-allowed' : ''
        }`}
      />
    </div>
  );
};

export const TextInput: FC<IInput> = ({ label, ...rest }) => {
  return (
    <div className={`flex flex-col my-4 w-full`}>
      <label
        className="medium text-sm text-neutral-900 flex justify-between items-center mb-2"
        htmlFor={rest.name}
      >
        <span>{label}</span>
        {rest.required && <span className="text-primary-700">*</span>}
      </label>

      <input
        id={rest.name}
        {...rest}
        className={`border border-neutral-300 rounded-md px-3 py-4 shadow-xsmall focus:outline-none ${
          rest.disabled ? 'cursor-not-allowed' : ''
        }`}
      />
    </div>
  );
};

interface IMultiSelect {
  singleSelect?: boolean;
  name: string;
  placeholder: string;
  className?: string;
  label: string;
  value?:
    | {
        value: any;
        label: any;
      }[]
    | {
        value: any;
        label: any;
      }
    | undefined;
  defaultValue?:
    | {
        value: any;
        label: any;
      }[]
    | {
        value: any;
        label: any;
      }
    | undefined;

  onChange:
    | ((
        newValue:
          | MultiValue<{
              value: any;
              label: any;
            }>
          | SingleValue<{ value: any; label: any }>,
        actionMeta: ActionMeta<{
          value: any;
          label: any;
        }>
      ) => void)
    | undefined;

  options:
    | OptionsOrGroups<
        { value: any; label: any },
        GroupBase<{ value: any; label: any }>
      >
    | undefined;

  filterOption?: (option: any, searchText: string) => boolean;
  required?: boolean;
}

export const MultiSelect: FC<IMultiSelect> = ({
  name,
  placeholder,
  value,
  onChange,
  options,
  className,
  label,
  singleSelect,
  filterOption,
  required,
}) => {
  return (
    <div className={`mt-7 flex flex-col ${className || 'input'}`}>
      <label className="medium text-sm text-neutral-900 flex justify-between items-center">
        <span>{label}</span>
        {required && <span className="text-primary-700">*</span>}
      </label>
      <Select
        isMulti={singleSelect ? false : true}
        name={name}
        placeholder={placeholder}
        styles={{
          control: (baseStyles) => ({
            ...baseStyles,
            padding: '10px 0',
            outline: 'none',
          }),
        }}
        value={value}
        onChange={onChange}
        options={options}
        filterOption={filterOption}
      />
    </div>
  );
};

interface IMultiSelectZ {
  singleSelect?: boolean;
  name: string;
  placeholder: string;
  className?: string;
  label: string;
  value?: number[] | undefined;
  onChange:
    | ((
        newValue:
          | MultiValue<{
              value: any;
              label: any;
            }>
          | SingleValue<{ value: any; label: any }>,
        actionMeta: ActionMeta<{
          value: any;
          label: any;
        }>
      ) => void)
    | undefined;
  options: { value: any; label: any }[] | undefined;

  filterOption?: (option: any, searchText: string) => boolean;
  required?: boolean;
}

export const MultiSelectZ: FC<IMultiSelectZ> = ({
  name,
  placeholder,
  value,
  onChange,
  options,
  className,
  label,
  singleSelect,
  filterOption,
  required,
}) => {
  return (
    <div className={`mt-7 flex flex-col ${className || 'input'}`}>
      <label className="medium text-sm text-neutral-900 flex justify-between items-center">
        <span>{label}</span>
        {required && <span className="text-primary-700">*</span>}
      </label>
      <Select
        isMulti={singleSelect ? false : true}
        name={name}
        placeholder={placeholder}
        styles={{
          control: (baseStyles) => ({
            ...baseStyles,
            padding: '10px 0',
            outline: 'none',
          }),
        }}
        value={
          options && value && value.length
            ? options.filter((item) => value.includes(item.value))
            : undefined
        }
        onChange={onChange}
        options={options}
        filterOption={filterOption}
      />
    </div>
  );
};

interface ISelect extends SelectHTMLAttributes<HTMLSelectElement> {
  label?: string;
  options: any[];
  optionLabel: string | ((x: any) => string);
  optionValue: string;
  onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
}

export const PrimarySelect: FC<ISelect> = ({
  onChange,
  label,
  options,
  optionLabel,
  optionValue,
  ...rest
}) => {
  const returnLabel = React.useCallback(
    (option: any) => {
      if (typeof optionLabel === 'string') {
        if (optionLabel.includes('.')) {
          const object = optionLabel.split('.');

          return option[object[0]][object[1]];
        }
        return option[optionLabel];
      }
    },
    [optionLabel]
  );

  return (
    <div className={`mt-7 ${rest.className || 'input'}`}>
      <label
        className="medium text-sm text-neutral-900 flex justify-between items-center"
        htmlFor={rest.name}
      >
        {label}
        {rest.required && <span className="text-primary-700">*</span>}
      </label>
      <select
        id={rest.name}
        {...rest}
        onChange={onChange}
        className={`border border-neutral-300 rounded-md px-3 py-4 shadow-xsmall focus:outline-none w-full select-input ${
          rest.disabled ? 'cursor-not-allowed' : ''
        }`}
      >
        <option value={'' || undefined}>{rest.placeholder}</option>
        {options.map((option, index) => {
          return (
            <option
              key={`${option[optionValue]}` + index}
              value={option[optionValue]}
            >
              {typeof optionLabel === 'string'
                ? returnLabel(option)
                : optionLabel(option)}
            </option>
          );
        })}
      </select>
    </div>
  );
};

interface IRadio {
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  label: string;
  name: string;
  className?: string;
  value: string | number;
  checked?: boolean;
}

export const PrimaryRadio: FC<IRadio> = ({
  name,
  onChange,
  value,
  checked,
  label,
  className,
}) => {
  return (
    <div className={`mt-7 flex items-center space-x-3 ${className}`}>
      <input
        type="radio"
        name={name}
        id={value.toString()}
        onChange={onChange}
        value={value}
        checked={checked}
      />
      <label htmlFor={value.toString()} className="text-sm">
        {label}
      </label>
    </div>
  );
};

interface ISecondaryRadio extends InputHTMLAttributes<HTMLInputElement> {
  label: string;
  containerClass?: string;
}

export const SecondaryRadio: FC<ISecondaryRadio> = ({
  label,
  containerClass,
  ...rest
}) => {
  return (
    <div
      className={`secondary mt-5 relative float-left box-border rounded-full mr-3 ${
        containerClass ? containerClass : 'w-3/12'
      }`}
    >
      <input type="radio" {...rest} />
      <div className="px-3 py-1 rounded-full w-full h-full flex justify-center items-center border border-primary-600 text-primary-600 text-sm input-text">
        <span>{label}</span>
      </div>
    </div>
  );
};

interface IPrimaryCheckbox extends InputHTMLAttributes<HTMLInputElement> {
  label: string;
}

export const PrimaryCheckbox: FC<IPrimaryCheckbox> = ({ label, ...rest }) => {
  return (
    <div className={`flex mt-7 items-center space-x-3 ${rest.className}`}>
      <input type="checkbox" {...rest} className="" />
      <label htmlFor={rest.name} className="text-sm">
        {label}
      </label>
    </div>
  );
};

interface IImgInput extends InputHTMLAttributes<HTMLInputElement> {
  label: string;
  containerClass?: string;
}

export const ImgInput: FC<IImgInput> = ({ label, containerClass, ...rest }) => {
  return (
    <div className={`relative flex items-end ${containerClass || 'input'}`}>
      <PrimaryInput {...rest} label={label} className="w-9/12 mr-4" />
      {rest.value && (
        <img
          src={rest.value as string}
          className="h-10 w-10 rounded-full object-cover overflow-hidden bg-gray-300 mb-2"
          alt="displayImage"
        />
      )}
    </div>
  );
};

interface AmountInputProps extends NumericFormatProps {
  label?: string;
}

export const AmountInput = ({ label, ...rest }: AmountInputProps) => {
  return (
    <div>
      <p className="text-sm text-neutral-900">{label ?? 'Enter Amount'}</p>
      <div className="flex items-center space-x-3 border border-neutral-300 rounded px-3 py-4">
        <p className="text-xs text-neutral-300">NGN</p>
        <NumericFormat
          className="input focus:outline-none flex-grow"
          thousandSeparator=","
          decimalScale={2}
          fixedDecimalScale
          {...rest}
        />
      </div>
    </div>
  );
};
