import { IPilotApi } from '../types';
import { instance } from './instance';

export const pilotsApi: IPilotApi = {
  getAllPilots: async (slug) =>
    await instance.get(`/pilot/all${slug ? `/${slug}` : ''}`),

  getPilot: async (id, query) =>
    await instance.get(
      `/admin/pilot/${id}${query ? `?${query.slice(1)}` : ''}`
    ),

  updatePilot: async (payload, id) =>
    await instance.put(`/admin/pilot/${id}`, payload),

  listBanks: async () => await instance.get(`/bani/list-banks`),

  verifyAccount: async (payload) =>
    await instance.post(`/bani/verify-account`, payload),

  unassignPilot: async (id) =>
    await instance.post(`/pilot/orders/${id}/unassign`),

  getKyc: async (id) => await instance.get(`/admin/kyc/${id}`),

  updateKyc: async (id, payload) =>
    await instance.put(`/admin/kyc/${id}`, payload),

  getPilotOrders: async (id, page, query) =>
    await instance.get(
      `/admin/pilot/${id}/orders?page=${page || 1}${query ?? ''}`
    ),

  assignPilotToKitchen: async (pilotId, payload) =>
    await instance.put(`/admin/pilot/assign-kitchen/${pilotId}`, payload),
};
