import { useQuery } from '@tanstack/react-query';
import { FoodItemParams } from '../types';
import { brandApi, categoriesApi, mealsApi } from '../api';

export const useFetch = () => {
  /// foooood
  const useFetchFoodItem = <T>(
    id?: string,
    params?: FoodItemParams,
    options?: any
  ) =>
    useQuery<T>(
      ['food-items', id, params],
      () => mealsApi.getFoodItems(id, params) as T,
      options
    );

  const useFetchFoodGroup = <T>(
    id?: string,
    params?: FoodItemParams,
    options?: any
  ) =>
    useQuery<T>(
      ['food-group', id, params],
      () => mealsApi.getFoodGroups(id, params) as T,
      options
    );

  const useFetchOptions = (params?: FoodItemParams, options?: any) =>
    useQuery(['options', params], () => mealsApi.getOptions(params), options);

  /// brand
  const useFetchBrand = <T>(slug?: string, options?: any) =>
    useQuery<T>(['brands', slug], () => brandApi.getBrand(slug) as T, options);

  /// category
  const useFetchCategory = <T>(slug?: string, options?: any) =>
    useQuery<T>(
      ['category', slug],
      () => categoriesApi.getCategory(slug) as T,
      options
    );

  return {
    useFetchBrand,
    useFetchOptions,
    useFetchFoodItem,
    useFetchFoodGroup,
    useFetchCategory,
  };
};
