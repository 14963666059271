import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useFetch } from './useFetch';
import React from 'react';
import {
  CreateFoodItem,
  FoodGroup,
  FoodItem,
  IBrand,
  ICategory,
  ResolvedResponse,
  Services,
} from '../types';
import { IOnChange } from '../views/components';
import { mealsApi } from '../api';
import { toast } from 'react-toastify';
import { parseError } from '../helpers';
import { useQueryClient } from '@tanstack/react-query';

export const useFoodItems = () => {
  const navigate = useNavigate();
  let { action, id } = useParams();
  const [searchParams] = useSearchParams();
  const duplicate = searchParams.get('duplicate');

  const queryClient = useQueryClient();

  const {
    useFetchBrand,
    useFetchCategory,
    useFetchFoodGroup,
    useFetchFoodItem,
  } = useFetch();

  const { data: brands, isLoading: brandsLoading } = useFetchBrand<IBrand[]>();

  const { data: categories, isLoading: categoriesLoading } =
    useFetchCategory<ICategory[]>();

  const { data: foodGroups, isLoading: groupLoading } = useFetchFoodGroup<
    ResolvedResponse<FoodGroup[]>
  >(undefined, { count: 500 });

  const { data: foodItem } = useFetchFoodItem<ResolvedResponse<FoodItem>>(
    duplicate ?? id,
    undefined,
    {
      enabled: !!id || !!duplicate,
    }
  );

  const [required, setRequired] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [newImage, setNewImage] = React.useState(true);
  const [payload, setPayload] = React.useState<CreateFoodItem>({
    name: '',
    price: 0,
    status: 'OPEN',
    comboItem: false,
    imageUrl: '',
    services: [],
  });

  const goBack = () => {
    navigate(-1);
  };

  const onGetInputValue = (
    event: React.SyntheticEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => {
    event.preventDefault();
    event.stopPropagation();

    const value = event.currentTarget.value;
    const name = event.currentTarget.name;

    if (name === 'comboItem') {
      return setPayload({
        ...payload,
        comboItem: parseInt(value) > 0 ? true : false,
      });
    }

    setPayload({
      ...payload,
      [name]: value,
    });
  };

  const onChangeBrand: IOnChange<IBrand> = (brand) => {
    if (brand) setPayload({ ...payload, brand: (brand as IBrand).id });
  };

  const onChangeCategories: IOnChange<ICategory> = (category) => {
    if (category)
      setPayload({
        ...payload,
        categories: (category as ICategory[]).map((c) => c.id),
      });
  };

  const onChangeCustomization: IOnChange<FoodGroup> = (group) => {
    if (group)
      setPayload({
        ...payload,
        customisations: (group as FoodGroup[]).map((c) => c.id!),
      });
  };

  const onChangeService: IOnChange<{ label: string; value: string }> = (
    services
  ) => {
    setPayload({
      ...payload,
      services: (services as { label: string; value: string }[]).map(
        (s) => s.value
      ) as Services[],
    });
  };

  const onChangeFoodGroup: IOnChange<FoodGroup> = (services) => {
    setPayload({
      ...payload,
      foodGroups: (services as FoodGroup[]).map((s) => s.id!),
    });
  };

  const createFoodItem = async (e?: React.SyntheticEvent<HTMLFormElement>) => {
    e?.stopPropagation();
    e?.preventDefault();
    setLoading(true);

    if (!required) return setLoading(false);

    if (!loading) {
      try {
        if (id) {
          const finalPayload = { ...payload };
          await mealsApi.updateFoodItem(id, finalPayload);

          toast.success('Food item updated!');
        } else {
          const finalPayload = { ...payload };
          await mealsApi.createFoodItem(finalPayload);

          toast.success('Food item created!');
        }

        queryClient.refetchQueries(['food-items']);
        navigate(-1);
      } catch (error) {
        toast.error(parseError(error));
      } finally {
        setLoading(false);
      }
    }
  };

  React.useEffect(() => {
    const { name, price, brand } = payload;

    setRequired(name.length > 0 && price > 0 && !!brand);
  }, [payload]);

  React.useEffect(() => {
    if (foodItem && foodItem.data && (id || duplicate)) {
      const item = foodItem.data;

      setPayload({
        name: duplicate ? `${item.name} copy` : item.name,
        services: item.services,
        status: item.status,
        price: item.price,
        comboItem: item.comboItem,
        description: item.description,
        brand: item.brand?.id,
        imageUrl: item.imageUrl,
        categories: item.categories?.map((c) => c.id),
        customisations: item.customisations?.map((c) => c.id!),
        foodGroups: (item.foodGroup as FoodGroup[])?.map((i) => i.id!),
      });

      if (item.imageUrl) {
        setNewImage(false);
      }
    }
  }, [foodItem, id, duplicate]);

  return {
    id,
    brands,
    action,
    loading,
    payload,
    required,
    foodItem,
    newImage,
    categories,
    foodGroups,
    groupLoading,
    brandsLoading,
    categoriesLoading,
    goBack,
    navigate,
    setPayload,
    setNewImage,
    onChangeBrand,
    createFoodItem,
    onGetInputValue,
    onChangeService,
    onChangeFoodGroup,
    onChangeCategories,
    onChangeCustomization,
  };
};
