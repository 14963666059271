import { backArrow } from '../../../../../assets/svg';
import {
  foodItemServices,
  foodItemStatus,
  foodItemType,
} from '../../../../../constants';
import { useFoodItems } from '../../../../../hooks';
import { Services } from '../../../../../types';
import {
  ImgInput,
  Loading,
  PrimaryButton,
  PrimaryInput,
  PrimarySelect,
  SecondaryButton,
  SelectInput,
  TextArea,
} from '../../../../components';
import { ImageUploader } from '../../../../components/image-uploader';

const CreateFoodItem = () => {
  const {
    id,
    brands,
    payload,
    loading,
    required,
    newImage,
    categories,
    foodGroups,
    brandsLoading,
    groupLoading,
    categoriesLoading,
    goBack,
    setPayload,
    setNewImage,
    onChangeBrand,
    createFoodItem,
    onChangeService,
    onGetInputValue,
    onChangeFoodGroup,
    onChangeCategories,
    onChangeCustomization,
  } = useFoodItems();

  return (
    <div>
      {(brandsLoading || groupLoading || categoriesLoading) && <Loading />}

      <div className="px-4 lg:px-8 py-8 w-full">
        <button
          className="flex items-center space-x-2 text-sm"
          onClick={goBack}
        >
          <img src={backArrow} alt="back" />
          <span className="semibold">Back</span>
        </button>

        <div className="mt-4">
          <h1 className="medium text-lg">
            {!id ? 'Create new' : 'Edit'} food item
          </h1>

          <form className="bg-white p-8 mt-2.5" onSubmit={createFoodItem}>
            <div className="grid grid-cols-2 gap-4">
              <PrimaryInput
                onChange={onGetInputValue}
                value={payload?.name}
                placeholder="Enter item name"
                required
                name="name"
                label="What is the name of the food item?"
                className="w-full"
              />
              <PrimaryInput
                onChange={onGetInputValue}
                value={`${payload?.price}`}
                placeholder="Enter item price"
                required
                name="price"
                label="What is the price of the food item?"
                className="w-full"
                type="number"
              />
              <PrimarySelect
                onChange={onGetInputValue}
                options={foodItemType}
                optionLabel="label"
                value={payload.comboItem ? 1 : 0}
                optionValue="value"
                label="Food item type"
                name="comboItem"
                placeholder="Select food item type"
                className="w-full"
              />
              <PrimarySelect
                onChange={onGetInputValue}
                options={foodItemStatus}
                optionLabel="label"
                value={payload.status}
                optionValue="value"
                label="Food item status"
                name="status"
                placeholder="Select status"
                className="w-full"
              />
              <TextArea
                onChange={onGetInputValue}
                value={payload?.description ?? ''}
                placeholder="Enter item description"
                name="description"
                label="Describe the food item"
                className="w-full col-span-2"
              />

              <SelectInput
                name="services"
                label="What delivery services applies to this food item?"
                placeholder="Select service(s)"
                options={foodItemServices}
                handleChange={onChangeService}
                getOptionValue={(item) => `${item.value}`}
                getOptionLabel={(item) => item.label}
                value={foodItemServices.filter((s) =>
                  payload.services.includes(s.value as Services)
                )}
                className="z-50 relative"
              />
              <SelectInput
                singleSelect
                name="brand"
                label="What brand owns this food item?"
                placeholder="Select Brand"
                options={brands ?? []}
                handleChange={onChangeBrand}
                getOptionValue={(item) => `${item.id}`}
                getOptionLabel={(item) => item.name}
                value={brands?.find((s) => payload.brand === s.id)}
              />
              <SelectInput
                name="categories"
                label="What categories can you find this food item in?"
                placeholder="Select categories"
                options={categories ?? []}
                handleChange={onChangeCategories}
                getOptionValue={(item) => `${item.id}`}
                getOptionLabel={(item) => item.name}
                value={categories?.filter((s) =>
                  payload.categories?.includes(s.id)
                )}
                className="z-40 relative"
              />
              <SelectInput
                name="foodGroups"
                label="What food groups does this item have?"
                placeholder="Select food groups"
                options={foodGroups?.data ?? []}
                handleChange={onChangeFoodGroup}
                getOptionValue={(item) => `${item.id}`}
                getOptionLabel={(item) => item.name ?? ''}
                value={foodGroups?.data?.filter((g) =>
                  payload.foodGroups?.includes(g.id!)
                )}
                className="z-20 relative"
              />
              <SelectInput
                name="customisations"
                label="What customizations can be applied to this food item?"
                placeholder="Select customizations"
                options={foodGroups?.data ?? []}
                handleChange={onChangeCustomization}
                getOptionValue={(item) => `${item.id}`}
                getOptionLabel={(item) => item.name ?? ''}
                value={foodGroups?.data?.filter((g) =>
                  payload.customisations?.includes(g.id!)
                )}
                className="z-20 relative"
              />
            </div>

            <div className="w-1/2 mt-8 relative">
              <p className="text-sm">Food item Image</p>
              <div className="flex items-end space-x-1 mt-2">
                <p className="text-xs text-gray-600 uppercase">Upload type:</p>
                <button
                  onClick={() => setNewImage((prev) => !prev)}
                  className="uppercase bold text-primary-600 text-xs z-10"
                  type="button"
                >
                  {newImage ? 'Upload new image' : 'Input image URL'} &#8595;
                </button>
              </div>

              {!newImage ? (
                <ImgInput
                  name="imageUrl"
                  label="Food Item Image"
                  containerClass="w-full"
                  value={payload.imageUrl ?? ''}
                  onChange={onGetInputValue}
                />
              ) : (
                <div className="mt-7">
                  <ImageUploader
                    name="imageUrl"
                    getUpload={(val) =>
                      setPayload({ ...payload, imageUrl: val })
                    }
                  />
                </div>
              )}
            </div>

            <div className="flex items-center space-x-4 mt-12">
              <PrimaryButton
                loading={loading}
                disabled={!required || loading}
                text={`${id ? 'Update' : 'Create'} food item`}
                type="submit"
              />
              <SecondaryButton text="Cancel" type="button" onClick={goBack} />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default CreateFoodItem;
